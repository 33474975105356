.form-step {
    padding: 20px 0;

    label {
        font-family: var(--urbanist) !important;
    }

    input {
        font-family: var(--urbanist) !important;
    }

    .css-113d811-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
        left: -7px;
        top: 0px;
        padding: 0px 11px;
        background-color: white !important;
    }


}

.css-12eb3rq-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-family: var(--urbanist) !important;
}

input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: black !important;
    transition: background-color 5000s ease-in-out 0s;
}

.MuiStepLabel-label {
    font-weight: 500 !important;
}

.MuiPaper-root {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
}


.stepHead,
.stepHead2 {
    line-height: 1.3;
    font-family: var(--urbanist) !important;
    font-size: 22px;
    margin-bottom: 10px;
    color: #333;
    font-weight: 600;
}

.stepHead2 {
    margin-bottom: 7px;
}

.MuiTableContainer-root {
    margin-bottom: 20px;
}

.MuiButton-containedPrimary {
    background-color: #1976d2 !important;
}

.MuiButton-containedSecondary {
    background-color: #9e9e9e !important;
}

.MuiFormControlLabel-root {
    margin-left: 0 !important;
}

.Accnt_steps_buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;


    button {
        font-family: var(--urbanist) !important;
        padding: 0.7rem;
        min-width: 200px;
        border-radius: 8px;
        box-shadow: none !important;
    }


}

.Next_bttn_accnForm {
    background-color: var(--bs-brand-2) !important;
    color: var(--black) !important;
    font-weight: 700;

    transition: 0.2s all ease;

    &:hover {
        background-color: var(--bs-brand-2-darken) !important;
    }
}

/* Custom CSS for the file upload area */
.border-dashed {
    border-style: dashed !important;
    border-width: 2px !important;
    border-color: #ced4da !important;
}

.upload-icon {
    font-size: 32px;
    color: #6c757d;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 50%;
}

/* Hover effects for the drop zone */
.border-dashed:hover,
.border-dashed.drag-over {
    background-color: rgba(0, 123, 255, 0.05);
    border-color: #0d6efd !important;
    cursor: pointer;
}

/* Animation for when files are being dragged over the area */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

.drag-over {
    animation: pulse 1.5s infinite ease-in-out;
}

/* Styles for the image preview container */
.image-preview-container {
    gap: 10px;
}

/* Individual image preview item */
.image-preview-item {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #dee2e6;
    padding: 3px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.image-preview-item img {
    transition: transform 0.2s;
}

.image-preview-item:hover img {
    transform: scale(1.05);
}

/* Delete button for image previews */
.delete-button {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    opacity: 0.8;
    transition: opacity 0.2s, background-color 0.2s;
}

.delete-button:hover {
    opacity: 1;
    background-color: #ff4d4f;
    color: white;
}


.aditional_note {
    .css-w4nesw-MuiInputBase-input-MuiOutlinedInput-input {
        border: none !important;

        &:hover {
            border: none !important;
        }

        &:focus {
            border: none !important;
        }
    }
}

.browswe_file_head {
    font-size: 20px;
}

@media screen and (max-width: 991px) {

    .stepHead,
    .stepHead2 {
        font-size: 19px;
    }
}

@media screen and (max-width: 601px) {
    .step_head_top {
        min-width: auto;
        overflow-x: auto;
        display: flex;
        justify-content: flex-start;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        padding: 10px;
    }

    .step_head_top .MuiStep-root {
        flex: 0 0 auto;
        scroll-snap-align: center;
        min-width: 200px;
    }
}

.wasAnyInjuredField {
    .css-1iw3t7y-MuiFormControl-root {
        width: 100%;
    }
}

.date_accidnet_pick {
    input {
        border: none !important;
    }

    .css-1b1fjlj-MuiFormControl-root-MuiTextField-root {
        width: 100%;
    }
}

.Passnger_add_injury_add_table {
    .css-y2ff7i-MuiPaper-root-MuiTableContainer-root {
        box-shadow: none !important;
        border: 1px solid #ced4da !important;
    }

    input {
        &:focus {
            border: none !important;
        }
    }
}

.injujry_select {
    min-width: 80px;
}

@media screen and (max-width: 465px) {
    .Accnt_steps_buttons {
        flex-wrap: wrap;

        button {
            min-width: 150px;
            gap: 0.5rem;
        }
    }
}