.mainTainceContrr {
  margin-top: 1.5rem;

  .icon {
    width: 18px;
    height: 18px;
  }

  /* Car Profile Card */
  .car-profile-card {
    background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
    border-radius: 12px;

    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;
    border: 1px solid var(--pale-grey);
  }

  .car-profile-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, var(--primary-color), var(--info-color));
  }

  .car-profile-header {
    display: flex;
    padding: 1.5rem;
    border-bottom: 1px solid var(--pale-grey);
  }

  .car-brand-logo {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 1.5rem;
    flex-shrink: 0;
  }

  .car-brand-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .car-brand-badge {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-color);
    color: var(--black);
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 600;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .car-details {
    margin-left: 1rem;
    flex: 1;
  }

  .car-model-info {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .car-model-info h2 {
    font-size: 22px;
    margin-top: 5px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .car-year {
    font-size: 1rem;
    color: var(--secondary-color);
    margin-left: 0.5rem;
  }

  .premium-tag {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 600;
    margin-left: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .car-owner {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  .owner-name,
  .pickup-date {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    font-size: 0.9rem;
  }

  .owner-name .icon,
  .pickup-date .icon {
    margin-right: 0.5rem;
    color: var(--primary-color);
  }

  .car-contact-details {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-item {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    color: var(--secondary-color);
  }

  .contact-item .icon {
    margin-right: 0.5rem;
    color: var(--secondary-color);
  }

  .car-profile-actions {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.02);
  }

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.25rem;
    border-radius: var(--border-radius);
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    transition: var(--transition);
    border: none;
    margin-left: 0.75rem;
  }

  .action-button .icon {
    margin-right: 0.5rem;
  }

  .action-button.primary {
    background-color: var(--primary-color);
    color: var(--black);
  }

  .action-button.primary:hover {
    background-color: var(--primary-dark);
  }

  .action-button.secondary {
    background-color: white;
    color: var(--secondary-color);
    border: 1px solid var(--border-color);
  }

  .action-button.secondary:hover {
    background-color: var(--light-color);
  }

  .dashboard-tabs {
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .tab-buttons {
    display: flex;
    overflow-y: hidden;
    border-bottom: 1px solid var(--border-color);
  }

  .tab-button {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    font-weight: 600;
    color: var(--secondary-color);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: var(--transition);
    position: relative;
  }

  .tab-button .tab-icon {
    margin-right: 0.5rem;
  }

  .tab-button:hover {
    font-weight: 600;
    color: var(--black);
  }

  .tab-button.active {
    font-weight: 600;
    color: var(--black);
  }

  .tab-button.active::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--primary-color);
  }

  .tab-content {
    padding: 1.5rem;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .tab-header h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  .tab-actions {
    display: flex;
    align-items: center;
  }

  .search-container {
    position: relative;
    margin-right: 1rem;
  }

  .search-input {
    padding: 0.5rem 1rem;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    font-size: 0.9rem;
    width: 250px;
    transition: var(--transition);
  }

  .search-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.1);
  }

  .filter-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    background-color: white;
    border: 1px solid var(--border-color);
    font-size: 0.9rem;
    cursor: pointer;
    transition: var(--transition);
  }

  .filter-button:hover {
    background-color: var(--light-color);
  }

  .filter-button .icon {
    margin-right: 0.5rem;
  }

  .summary-card {
    height: 100%;
    background-color: white;
    border-radius: var(--border-radius);
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    /* display: flex;
    align-items: center; */
    border-left: 4px solid var(--primary-color);
  }

  .summary-card:last-child {
    margin-right: 0;
  }

  .summary-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(13, 110, 253, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    color: var(--primary-color);
  }

  .summary-icon svg {
    width: 16px;
    height: 16px;
  }

  .summary-contentHead {
    font-size: 0.85rem;
    color: var(--secondary-color);
    margin: 0 0 0 0;
  }

  .summary-content p {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
  }

  /* Table Styles */
  .table-container {
    background-color: white;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
  }

  .data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  .data-table thead {
    background-color: #f8f9fa;
  }

  .data-table th {
    padding: 1rem;
    font-size: 0.85rem;
    font-weight: 600;
    color: var(--secondary-color);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 2px solid var(--border-color);
  }

  .th-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sort-icons {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }

  .sort-icon {
    width: 12px;
    height: 12px;
    color: #ccc;
    cursor: pointer;
  }

  .sort-icon:hover {
    color: var(--primary-color);
  }

  .data-table td {
    padding: 1rem;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
  }

  .data-row {
    transition: var(--transition);
  }

  .data-row:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .id-cell {
    font-weight: 600;
    color: var(--secondary-color);
  }

  .date-cell,
  .mechanic-cell,
  .type-cell {
    display: flex;
    align-items: center;
  }

  .cell-icon {
    margin-right: 0.5rem;
    color: var(--secondary-color);
  }

  .amount-cell,
  .cost-cell {
    font-weight: 600;
  }

  .payment-mode-cell {
    display: flex;
    align-items: center;
  }

  .payment-mode {
    padding: 0.2rem 0.6rem;
    font-size: 0.85em;
    font-weight: 500;
    border-radius: 12px;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
  }

  .payment-mode.paytm {
    background-color: #f0f7ff;
    color: #0066ff;
    border-color: #cce5ff;
  }

  .payment-mode.online {
    background-color: #f0f7ff;
    color: #0066ff;
    border-color: #cce5ff;
  }

  .payment-mode.cash {
    background-color: #f0fff0;
    color: #28a745;
    border-color: #d4edda;
  }

  .payment-mode.card {
    background-color: #fff0f0;
    color: #dc3545;
    border-color: #f8d7da;
  }

  .status-cell {
    display: flex;
    align-items: center;
  }

  .status-icon {
    margin-right: 0.5rem;
  }

  .status-icon.completed {
    color: var(--success-color);
  }

  .status-icon.pending {
    color: var(--warning-color);
  }

  .status-text {
    font-weight: 500;
  }

  .status-text.completed {
    color: var(--success-color);
  }

  .status-text.pending {
    color: var(--warning-color);
  }

  .status-text.in-progress {
    color: var(--primary-color);
  }

  .status-dropdown {
    position: relative;
  }

  .status-select {
    padding: 0.35em 0.65em;
    font-size: 0.85em;
    font-weight: 500;
    border-radius: 20px;
    border: 1px solid #e9ecef;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    width: 140px;
  }

  .status-select.completed {
    background-color: rgba(25, 135, 84, 0.1);
    color: var(--success-color);
    border-color: rgba(25, 135, 84, 0.2);
  }

  .status-select.in-progress {
    background-color: rgba(13, 110, 253, 0.1);
    color: var(--primary-color);
    border-color: rgba(13, 110, 253, 0.2);
  }

  .status-select.pending {
    background-color: rgba(255, 193, 7, 0.1);
    color: #856404;
    border-color: rgba(255, 193, 7, 0.2);
  }

  .actions-cell {
    display: flex;
    align-items: center;
  }

  .action-btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    background-color: white;
    margin-right: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
  }

  .action-btn:hover {
    background-color: var(--light-color);
  }

  .action-icon {
    width: 16px;
    height: 16px;
    color: var(--secondary-color);
  }

  .mechanic-avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.85rem;
    margin-right: 0.75rem;
  }

  /* Table Footer */
  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }

  .pagination {
    display: flex;
    align-items: center;
  }

  .pagination-btn {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: white;
    border: 1px solid var(--border-color);
    font-size: 0.9rem;
    cursor: pointer;
    transition: var(--transition);
  }

  .pagination-btn:hover {
    background-color: var(--light-color);
  }

  .pagination-pages {
    display: flex;
    margin: 0 0.5rem;
  }

  .pagination-page {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: white;
    border: 1px solid var(--border-color);
    margin: 0 0.25rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: var(--transition);
  }

  .pagination-page:hover {
    background-color: var(--light-color);
  }

  .pagination-page.active {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
  }

  .items-per-page {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
  }

  .items-per-page span {
    margin: 0 0.5rem;
    color: var(--secondary-color);
  }

  .items-select {
    padding: 0.35em 0.65em;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    font-size: 0.9rem;
  }

  .upcomingHdrr {
    display: flex;
    align-items: center;

  }

  /* Upcoming Maintenance */
  .upcoming-maintenance {
    height: 138px;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: linear-gradient(135deg, #fff9c4 0%, #ffecb3 100%);
    padding: 1rem;
    border: 1px solid var(--pale-grey);
    border-radius: 10px;
    margin-bottom: 0px;
  }

  .upcoming-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgba(255, 193, 7, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    color: #856404;
  }

  .upComing_data {
    display: flex;
    align-items: center;
  }

  .aler_icnn {
    height: 18px;
    width: 18px;
    margin-right: 0.6rem;
  }

  .upcoming-content h4,
  .upcoming_content2 h4 {
    line-height: normal;
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 0.25rem 0;
    color: #856404;
  }

  .upcoming-maintenance p {
    padding-left: 1.7rem;
    margin-top: 5px;
    font-size: 14px;

    color: #856404;
  }

  .highlight-date {
    font-weight: 600;
  }

  .Schedule_icn_txt {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 5px;

    span {
      height: fit-content;
      margin-top: 5px;
      border: 1px solid #FBBF24;
      background-color: #FFFBEB;
      color: #9A6B2F;
      padding: 2px 10px;
      border-radius: 4px;
      font-size: 12px;
      display: flex;
      align-items: center;
      border-radius: 12px;
    }
  }

  .upcoming-action {
    width: fit-content;
    margin-top: 0;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    background-color: white;
    border: 1px solid rgba(255, 193, 7, 0.3);
    color: #856404;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
    text-transform: none;

  }

  .upcoming-action:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .upcoming-action .icon {
    margin-left: 0.5rem;
  }

  /* Subscription Banner */
  .subscription-banner {
    background: linear-gradient(135deg, #2b5876 0%, #4e4376 100%);
    border-radius: var(--border-radius);
    padding: 2rem;
    color: white;
    box-shadow: var(--box-shadow);
  }

  .subscription-content {
    text-align: center;
  }

  .subscription-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .subscription-form {
    display: flex;
    max-width: 500px;
    margin: 0 auto;
  }

  .subscription-input {
    flex: 1;
    padding: 0.75rem 1.25rem;
    border-radius: 30px 0 0 30px;
    border: none;
    font-size: 1rem;
  }

  .subscription-input:focus {
    outline: none;
  }

  .subscription-button {
    padding: 0.75rem 1.5rem;
    border-radius: 0 30px 30px 0;
    background-color: var(--primary-color);
    color: white;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: var(--transition);
  }

  .subscription-button:hover {
    background-color: var(--primary-dark);
  }

  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .custom-modal {
    background-color: white;
    border-radius: var(--border-radius);
    width: 800px;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid var(--border-color);
  }

  .modal-header h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--secondary-color);
  }

  .modal-progress {
    padding: 1.5rem;
    border-bottom: 1px solid var(--border-color);
  }

  .progress-steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step-number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--light-color);
    border: 2px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 0.5rem;
    transition: var(--transition);
  }

  .progress-step.active .step-number {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
  }

  .step-label {
    font-size: 0.85rem;
    color: var(--secondary-color);
    transition: var(--transition);
  }

  .progress-step.active .step-label {
    color: var(--primary-color);
    font-weight: 600;
  }

  .progress-line {
    flex: 1;
    height: 2px;
    background-color: var(--border-color);
    margin: 0 0.5rem;
    position: relative;
    top: -16px;
  }

  .modal-body {
    padding: 1.5rem;
  }

  .step-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: var(--dark-color);
  }

  .form-row {
    display: flex;
    margin: 0 -0.75rem;
  }

  .form-group {
    flex: 1;
    padding: 0 0.75rem;
    margin-bottom: 1.25rem;
  }

  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .input-with-icon {
    position: relative;
  }

  .input-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--secondary-color);
  }

  .input-with-icon .form-control {
    padding-left: 2.5rem;
  }

  .form-control {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    font-size: 0.9rem;
    transition: var(--transition);
  }

  .form-control:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.1);
  }

  .maintenance-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .maintenance-type-option {
    position: relative;
    cursor: pointer;
  }

  .maintenance-type-option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .option-content {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    transition: var(--transition);
  }

  .maintenance-type-option input:checked~.option-content {
    background-color: rgba(13, 110, 253, 0.05);
    border-color: var(--primary-color);
  }

  .option-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(13, 110, 253, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: var(--primary-color);
    transition: var(--transition);
  }

  .maintenance-type-option input:checked~.option-content .option-icon {
    background-color: var(--primary-color);
    color: white;
  }

  .option-label {
    font-weight: 500;
  }

  .file-upload-area {
    border: 2px dashed var(--border-color);
    border-radius: var(--border-radius);
    padding: 2rem;
    text-align: center;
    background-color: var(--light-color);
    cursor: pointer;
    transition: var(--transition);
  }

  .file-upload-area:hover {
    border-color: var(--primary-color);
  }

  .upload-icon-container {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .upload-icon {
    color: var(--primary-color);
    width: 32px;
    height: 32px;
  }

  .file-upload-area h5 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .browse-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.5rem 1.25rem;
    border-radius: 20px;
    font-weight: 500;
    margin: 1rem 0;
    cursor: pointer;
    transition: var(--transition);
  }

  .browse-button:hover {
    background-color: var(--primary-dark);
  }

  .file-info {
    font-size: 0.85rem;
    color: var(--secondary-color);
    margin: 0;
  }

  .uploaded-files {
    margin-top: 1.5rem;
  }

  .uploaded-file {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-color);
    margin-bottom: 0.5rem;
  }

  .file-preview {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 1rem;
  }

  .file-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .file-details {
    flex: 1;
  }

  .file-name {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .file-size {
    font-size: 0.8rem;
    color: var(--secondary-color);
  }

  .remove-file {
    background: none;
    border: none;
    color: var(--danger-color);
    font-size: 1.25rem;
    cursor: pointer;
  }

  .confirmation-card {
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;
  }

  .confirmation-header {
    background-color: var(--light-color);
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--border-color);
  }

  .confirmation-header h5 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  .confirmation-body {
    padding: 1.5rem;
  }

  .confirmation-row {
    display: flex;
    margin-bottom: 1rem;
  }

  .confirmation-row:last-child {
    margin-bottom: 0;
  }

  .confirmation-label {
    width: 150px;
    font-weight: 500;
    color: var(--secondary-color);
  }

  .confirmation-value {
    flex: 1;
  }

  .confirmation-value.highlight {
    font-weight: 700;
    color: var(--primary-color);
  }

  .confirmation-alert {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: rgba(25, 135, 84, 0.1);
    border-radius: var(--border-radius);
    color: var(--success-color);
  }

  .alert-icon {
    margin-right: 1rem;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem 1.5rem;
    border-top: 1px solid var(--border-color);
  }

  .btn-secondary {
    background-color: white;
    color: var(--secondary-color);
    border: 1px solid var(--border-color);
    padding: 0.75rem 1.5rem;
    border-radius: var(--border-radius);
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
    margin-right: 1rem;
  }

  .btn-secondary:hover {
    background-color: var(--light-color);
  }

  .btn-secondary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .btn-primary,
  .btn-success {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: var(--border-radius);
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
    border: none;
  }

  .btn-primary {
    background-color: var(--primary-color);
    color: white;
  }

  .btn-primary:hover {
    background-color: var(--primary-dark);
  }

  .btn-success {
    background-color: var(--success-color);
    color: white;
  }

  .btn-success:hover {
    background-color: #146c43;
  }

  .btn-icon {
    margin-left: 0.5rem;
  }

  /* Responsive Styles */
  @media (max-width: 992px) {
    .tab-header h3 {
      padding-left: 5px;
      line-height: 20px;
      padding-top: 2rem !important;
    }

    .car-brand-logo {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }

    .summary-card {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    .maintenance-types {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    .car-profile-actions {
      flex-direction: column;
    }

    .car-profile-header {
      padding-top: 1rem !important;
      padding-bottom: 0rem !important;
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .car-owner {
      margin-bottom: px;
    }

    .car-details {
      h2 {
        font-size: 20px !important;
      }

      span {
        font-size: 12px !important;
      }
    }

    .tab-content {
      padding: 0rem;
    }

    .action-button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 0.5rem;
    }

    .tab-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .tab-actions {
      margin-top: 1rem;
      width: 100%;
    }

    .search-container {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.5rem;
    }

    .search-input {
      width: 100%;
    }

    .filter-button {
      width: 100%;
    }

    .table-footer {
      flex-direction: column;
    }

    .pagination {
      margin-bottom: 1rem;
    }

    .form-row {
      flex-direction: column;
    }

    .subscription-form {
      flex-direction: column;
    }

    .subscription-input {
      border-radius: 30px;
      margin-bottom: 0.5rem;
    }

    .subscription-button {
      border-radius: 30px;
    }
  }

  @media (max-width: 576px) {
    .car-profile-header {
      flex-direction: column;
    }

    .car-details {
      margin-left: 0px;
    }

    .progress-steps {
      display: none;
    }

    .tab-button {
      padding: 1rem !important;
    }

    .upcoming-maintenance {
      padding: 0.8rem !important;
    }

    .upcoming-content h4 {
      line-height: 20px;
      font-size: 14px !important;
    }

    .upcoming-content p {
      font-size: 12px !important;
    }

    .car-owner {
      margin-bottom: 0px;
      flex-direction: column;
      align-items: flex-start;
    }

    .owner-name,
    .pickup-date {
      margin-bottom: 0.5rem;
    }

    .table-container {
      overflow-x: auto;
    }

    .data-table {
      min-width: 800px;
    }
  }

  @media (max-width: 410px) {
    .upcoming-maintenance {
      align-items: center;

      flex-direction: column !important;

      h4 {
        margin-top: 0.5rem;
        text-align: center;
      }
    }

    .upcoming-action {
      margin-top: 0.7rem;
    }
  }
}

.transaction_id span {
  color: #007bff;

  cursor: pointer;
}

.transaction_id span:hover {
  opacity: 0.8;
}

.lessbttn_ {
  font-size: 12px;
}

.descraseTxt {
  font-size: 15px !important;
}

.status-dropdown {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 6px;
  text-transform: capitalize;
}

.status-icon {
  width: 18px;
  height: 18px;
}

.yellow-icon {
  color: #f1c40f;
}

.green-icon {
  color: #27ae60;
}

.red-icon {
  color: #e74c3c;
}

.blue-icon {
  color: #007bff;
}

/*------------------------modal  styling----------------*/
/* MaintenanceModal.css */
.mainModal_style {
  h3 {
    font-size: 20px !important;
    color: var(--black);
  }

  .modal-xl {
    max-width: 1140px;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* Icons */
  .section-icon,
  .title-icon {
    width: 20px;
    height: 20px;
    stroke-width: 2;
  }

  .title-icon {
    width: 24px;
    height: 24px;
  }

  .badge-icon {
    width: 12px;
    height: 12px;
  }

  .action-icon {
    width: 18px;
    height: 18px;
    color: #0d6efd;
  }

  /* Button styles */
  .btn-icon {
    transition: background-color 0.2s;
  }

  .btn-icon:hover {
    background-color: rgba(13, 110, 253, 0.1);
  }

  .action-btn {
    border: none;
    background: transparent;
  }

  /* Badge customizations */
  .bg-success-light {
    background-color: rgba(25, 135, 84, 0.15) !important;
  }

  .bg-danger-light {
    background-color: rgba(220, 53, 69, 0.15) !important;
  }

  .bg-warning-light {
    background-color: rgba(255, 193, 7, 0.15) !important;
  }

  .text-success {
    color: #198754 !important;
  }

  .text-danger {
    color: #dc3545 !important;
  }

  .text-warning {
    color: #ff9800 !important;
  }

  /* Info panels */
  .info-panel {
    background-color: #f8f9fa;
    border-radius: 0.5rem;
  }

  /* Utility classes */
  .gap-1 {
    gap: 0.25rem;
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .last-item-no-border:last-child {
    border-bottom: none !important;
  }
}


.Maintn_PaymentContrr {
  width: 100%;
  margin-top: 3rem;
}

.maintance_card {
  height: 100%;
  padding: 1rem;
  border: 1px solid var(--pale-grey);

  border-radius: 8px;
}




/*-----------------------Car _design-----------------------*/
.paymentCardContrr {
  height: 138px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px !important;
  overflow: hidden;

}

.payment-card {

  height: 100%;

  margin-bottom: 0px;
  border: 1px solid var(--pale-grey);
  width: 100%;
  box-shadow: none !important;
  background: white;
  padding: 1rem;
  border-radius: 10px !important;


  .payment-content {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
  }

  .payment-field {
    display: flex;
    align-items: center;
  }

  .field-icon {
    background-color: #f3f4f6;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    color: #4f46e5;
  }

  .field-content {
    display: flex;
    flex-direction: column;
  }

  .field-label {
    font-size: 12px;
    color: #6b7280;
    margin-bottom: 2px;
  }

  .field-value {
    font-size: 15px;
    font-weight: 500;
    color: #1f2937;
  }

  .payment-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 15px 16px;
    background-color: #f9fafb;
    border-radius: 8px;
  }

  .amount-label {
    font-size: 15px;
    font-weight: 500;
    color: #4b5563;
  }

  .amount-value {
    font-size: 17px;
    font-weight: 700;
    color: #1f2937;
  }

  .pay-button {
    font-family: var(--urbanist);
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    background-color: var(--bs-brand-2);
    color: black;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 0px;
    font-size: 13px;
  }

  .pay-button:hover {
    background-color: var(--bs-brand-2-darken);
    transform: translateY(-2px);

  }

  .upcoming_due_py {
    line-height: normal !important;
    width: 100%;
    font-size: 17px;
  }

  .amount_paymnt_contr {

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 10px;
  }
}



.datepicker-container {
  position: absolute;
  top: 3.5rem;
  right: 0px;
  background: white;
  padding: 0px;

  z-index: 10;
}

.save_edit_clndrr {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.6rem;

  button {
    font-size: 14px !important;
  }
}

.ScheduleSHowClass {
  margin-top: 0px !important;
  font-size: 14px !important;
}

.upcoming_content2 {
  h4 {
    font-size: 17px;
  }
}

.succes_fully_reschdule {
  cursor: default;
  margin-left: 3.3rem;
  margin-top: 0.5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: #dcfde6;
  padding: 2px 10px;
  border-radius: 50px;
  gap: 0.5rem;

  .dot {
    height: fit-content !important;
    background-color: #23c55f !important;
    color: #23c55f !important;
    padding: 5px;
    border-radius: 100%;

  }

  p {
    font-size: 13px !important;
    margin-top: 0px !important;
    padding-left: 0px !important;
    color: #16803e !important;
  }
}

.Maintxt {
  margin-top: 2px !important;
}


@media screen and (max-width: 628px) {
  .mainTainceContrr {
    .upcoming-maintenance {
      min-height: 138px;
      /* flex-direction: column; */
      height: 100%;
    }
  }
}

@media screen and (max-width: 607px) {
  .mainTainceContrr {
    .button-group {
      margin-top: 14px;
    }
  }
}

.upcoming-maintenance {
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    margin-top: -5px !important;
    display: flex;
  }

  .react-datepicker__year-select {
    min-width: 73px;
  }

  .react-datepicker__month-select {
    background-color: white;
  }
}