.privary_policy_Container {

    /* Privacy Policy Page Styles */
    .custom-container {
        max-width: 1400px;
        margin-bottom: 50px;
    }

    .rounded-12 {
        border-radius: 12px;
    }

    .bg-overlay {
        position: relative;
        height: 300px;
    }

    .bg-overlay::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 12px;
    }

    .img-banner {
        object-fit: cover;
        height: 100%;
    }

    .z-1 {
        z-index: 1;
    }

    .pb-70 {
        padding-bottom: 70px;
    }

    .privacy-policy-container {
        line-height: 1.6;
    }

    .policy-header {
        margin-bottom: 30px;

        p {
            font-size: 16px !important;
        }
    }

    .policy-section {
        margin-bottom: 25px;
    }

    .policy-section h4 {
        font-size: 25px;
        color: #333;
        margin-bottom: 3px;
    }

    .policy-section h5 {
        margin-top: 5px !important;
        color: #555;
        font-size: 1.1rem;
    }

    .policy-section ul {
        margin-bottom: 15px;
    }

    .policy-section ul li {
        margin-bottom: 5px;
    }

    .contact-info {
        background-color: #f8f9fa;
        padding: 15px;
        border-radius: 8px;

        span {
            font-weight: 600;
        }
    }

    @media (max-width: 768px) {
        .bg-overlay {
            height: 200px;
        }

        .policy-header h3 {
            font-size: 1.8rem;
        }

        .policy-section h4 {
            font-size: 1.4rem;
        }
    }
}

.contact_us_txt {
    font-size: 22px !important;
}